<template>
  <div v-if="load" class="pt-0">
    <!-- This can be uncommented if data validation is needed again -->
    <!-- <v-alert type="error" v-if="dataErrorCanvasID === chartConfig.canvasID">Mixed % and non-% data / missing data</v-alert> -->
    <!-- Bar Chart -->
    <div v-if="item.chartType === 'Bar'">
      <div
        :id="'chartContainer_' + chartConfig.canvasID"
        v-if="displayChart"
        class="chart-container"
        :style="
          'position: relative; height:' +
          computedChartHeight +
          '; width:' +
          chartWidth +
          ';'
        "
      >
        <BarChart
          :chart-id="chartConfig.canvasID"
          :chartConfig="chartConfig"
          @data-error="setDataErrorAlert"
        />
      </div>
    </div>
    <!-- Stacked Bar Chart -->
    <div v-if="item.chartType === 'Stacked Bar'">
      <div
        :id="'chartContainer_' + chartConfig.canvasID"
        v-if="displayChart"
        class="chart-container"
        :style="
          'position: relative; height:' +
          computedChartHeight +
          '; width:' +
          chartWidth +
          ';'
        "
      >
        <BarChart
          :chart-id="chartConfig.canvasID"
          :chartConfig="chartConfig"
          :stacked="true"
          @data-error="setDataErrorAlert"
        />
      </div>
    </div>
    <!-- Line Chart -->
    <div v-if="item.chartType === 'Line'">
      <div
        :id="'chartContainer_' + chartConfig.canvasID"
        v-if="displayChart"
        class="chart-container"
        :style="
          'position: relative; height:' +
          computedChartHeight +
          '; width:' +
          chartWidth +
          ';'
        "
      >
        <LineChart
          :chart-id="chartConfig.canvasID"
          :chartConfig="chartConfig"
          @data-error="setDataErrorAlert"
        />
      </div>
    </div>
    <v-card-actions>
      <p class="min-accessible-size">Source: {{ item.itemSource }}</p>
      <v-spacer></v-spacer>
      <v-btn
        class="mx-2 button-icon-text"
        variant="elevated"
        color="blue-grey"
        @click="exportData"
        :aria-label="'Download ' + getFileName()"
        :title="'Download ' + getFileName()"
      >
        <v-icon size="large">mdi-microsoft-excel</v-icon>
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import BarChart from "@/components/BarChart.vue";
import LineChart from "@/components/LineChart.vue";
import { exportCSVFile } from "@/mixins/ExportCSVFile";

export default {
  name: "CHARTS",
  data: () => ({
    exportCSVFile: exportCSVFile,
    chartIndicatorValues: null,
    colours: ["#51627C", "#3EDBCF", "#C295FC"],
    displayChart: false,
    chartAxis: null,
    chartConfig: {
      labels: [],
      datasets: [],
    },
    dataErrorCanvasID: null,
  }),
  components: {
    BarChart,
    LineChart,
  },
  computed: {
    computedChartHeight() {
      if (this.chartHeight) {
        return this.chartHeight;
      }
      if (this.$vuetify.display.xs) {
        return "auto";
      }
      if (this.$vuetify.display.sm) {
        return "auto";
      }
      if (this.$vuetify.display.md) {
        return "60vh";
      }
      if (this.$vuetify.display.lg) {
        return "60vh";
      }
      if (this.$vuetify.display.xl) {
        return "60vh";
      }
      return "60vh";
    },
  },
  props: {
    load: {
      type: Boolean,
      required: false,
      default: false,
    },
    reportThemeObject: {
      type: Object,
      default() {
        return {};
      },
    },
    indicatorValues: {
      type: Object,
      default() {
        return {};
      },
    },
    item: {
      type: Object,
      default() {
        return {};
      },
    },
    comparators: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    comparatorsList: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    chartWidth: {
      type: String,
      required: false,
      default: "95vw",
    },
    chartHeight: {
      type: String,
      required: false,
    },
  },
  mounted() {},
  methods: {
    getFileName(underscores = false) {
      let filename =
        `Local Insight ${this.reportThemeObject.name} ${this.item.itemName || ""}`.trim();

      return underscores ? filename.replaceAll(" ", "_") : filename;
    },
    setChartIndicatorValues() {
      let requiredIndicatorValues = {};

      // get the HTML tables indicators
      if (this.item.syncChartWithHTMLTableRef !== null) {
        for (let i = 0; i < this.reportThemeObject.config.length; i++) {
          if (
            this.reportThemeObject.config[i].indicatorHTMLTableRef ===
            this.item.syncChartWithHTMLTableRef
          ) {
            for (
              let j = 0;
              j < this.reportThemeObject.config[i].indicators.length;
              j++
            ) {
              for (let key in this.indicatorValues) {
                let split = key.split(".");
                if (
                  this.reportThemeObject.config[i].indicators[j]
                    .indicatorCode === split[1] &&
                  this.reportThemeObject.config[i].indicators[j].date ===
                    split[2]
                ) {
                  requiredIndicatorValues[key] = this.indicatorValues[key];
                }
              }
            }
          }
        }
      } else {
        // else we are using the manually selected chart indicators
        for (let j = 0; j < this.item.indicators.length; j++) {
          for (let key in this.indicatorValues) {
            let split = key.split(".");
            if (
              this.item.indicators[j].indicatorCode === split[1] &&
              this.item.indicators[j].date === split[2]
            ) {
              requiredIndicatorValues[key] = this.indicatorValues[key];
            }
          }
        }
      }
      this.chartIndicatorValues = requiredIndicatorValues;
    },
    exportData() {
      var data = [];
      let i;
      var row;
      let filename = this.getFileName(true);

      // First row of headers
      row = {
        header: this.item.chartType === "Line" ? "Date" : "Indicator",
      };

      for (i = 0; i < this.comparators.length; i++) {
        row[this.comparators[i]] = this.comparitorName(this.comparators[i]);
      }

      data.push(row);

      // Organise data into a 2d grid
      var grid = [];
      for (var key in this.chartIndicatorValues) {
        // ignore null values
        if (!this.chartIndicatorValues[key]) {
          continue;
        }
        var split = key.split(".");

        // Find the date coverage text for the indicator code + date
        for (var indicatorIndex in this.item.indicators) {
          if (
            this.item.indicators[indicatorIndex].indicatorCode == split[1] &&
            this.item.indicators[indicatorIndex].date == split[2]
          ) {
            var dateCoverageText =
              this.item.chartType === "Line"
                ? this.item.indicators[indicatorIndex].dateCoverageText
                : this.item.indicators[indicatorIndex].indicatorName;
          }
        }

        var areaCode = split[0];
        var indicator = split[1] + "." + split[2];

        // Ignore counts if dynamic indicator
        if (split[1].includes("*")) {
          if (typeof split[4] === "undefined") {
            continue;
          }
        }

        // Init array if it doesn't exist already
        if (typeof grid[indicator] === "undefined") {
          grid[indicator] = [];
          grid[indicator].name = name;
        }

        grid[indicator].name = dateCoverageText;

        grid[indicator][areaCode] = this.chartIndicatorValues[key].replace(
          /[,]/g,
          "",
        );
      }

      // Build csv data
      for (indicator in grid) {
        row = {};
        // Left side are indicator names
        row[grid[indicator].name] = grid[indicator].name;

        for (areaCode in this.comparators) {
          var value = grid[indicator][this.comparators[areaCode]];
          // Make sure there is an empty cell if there is no value, so the columns line up
          if (!value) {
            value = " ";
          }
          row[this.comparators[areaCode]] = value;
        }
        data.push(row);
      }

      this.exportCSVFile(null, data, filename);
    },
    buildChartConfig() {
      // if the chart is synced with a HTML table
      if (this.item.syncChartWithHTMLTableRef !== null) {
        if (this.item.inverseChartData) {
          this.invertedHTMLSyncChartConfig();
        } else {
          this.nonInvertedHTMLSyncChartConfig();
        }
        // if a chart is NOT synced with a HTML table
      } else {
        // is this manual data?
        if (this.item.chartYCommaSeperatedValues !== null) {
          let datasets = null;
          // are we looking at json?
          if (this.item.chartYCommaSeperatedValues.includes("{")) {
            datasets = JSON.parse(this.item.chartYCommaSeperatedValues);

            // Default colours if not specified
            var colourCounter = 0;
            for (let i = 0; i < datasets.length; i++) {
              if (!datasets[i].backgroundColor) {
                datasets[i].backgroundColor = this.colours[colourCounter];
              }
              colourCounter++;
              // Go back to first colour if out of colours
              colourCounter %= this.colours.length;
            }
            // nah...just a straight forward list
          } else {
            datasets = [
              {
                label: "",
                backgroundColor: this.colours[0],
                data: this.item.chartYCommaSeperatedValues.split(","),
              },
            ];
          }
          this.chartConfig = {
            canvasID: this.item.chartRef,
            labels: this.item.chartXCommaSeperatedValues.split(","),
            datasets: datasets,
            title: !this.item.chartDescription
              ? ""
              : this.item.chartDescription
                  .replace("<p>", "")
                  .replace("</p>", ""),
          };
          // change some colours if this is a line chart
          if (this.item.chartType === "Line") {
            for (let i = 0; i < this.chartConfig.datasets.length; i++) {
              this.chartConfig.datasets[i].borderColor =
                this.chartConfig.datasets[i].backgroundColor;
              this.chartConfig.datasets[i].backgroundColor = "transparent";
              this.chartConfig.datasets[i].pointBackgroundColor = "#000000";
              this.chartConfig.datasets[i].tension = 0.2;
            }
          }
          // so this is looking like manually selected indicators
        } else {
          if (this.item.inverseChartData) {
            this.invertedHTMLSyncChartConfig();
          } else {
            this.nonInvertedHTMLSyncChartConfig();
          }
        }
      }
    },
    nonInvertedHTMLSyncChartConfig() {
      // set these
      var split = [];
      var tempLabels = [];
      var tempDatasets = [];
      var colourCounter = 0;
      var indicatorValue;
      // sort out the labels
      for (var key in this.chartIndicatorValues) {
        split = key.split(".");
        if (typeof tempLabels[split[0]] === "undefined") {
          tempLabels[split[0]] = this.comparitorName(split[0]);
        }
      }
      // sort out the datasets
      for (key in this.chartIndicatorValues) {
        split = key.split(".");

        // Find the date coverage text for the indicator code + date
        for (var indicatorIndex in this.item.indicators) {
          if (
            this.item.indicators[indicatorIndex].indicatorCode == split[1] &&
            this.item.indicators[indicatorIndex].date == split[2]
          ) {
            var dateCoverageText =
              this.item.indicators[indicatorIndex].dateCoverageText;
          }
        }

        if (typeof tempDatasets[split[1] + "." + split[2]] === "undefined") {
          tempDatasets[split[1] + "." + split[2]] = {
            label: split[3] + " - " + dateCoverageText,
            backgroundColor: this.colours[colourCounter],
            data: [],
          };
          if (this.item.chartType === "Line") {
            tempDatasets[split[1] + "." + split[2]].backgroundColor =
              "transparent";
            tempDatasets[split[1] + "." + split[2]].borderColor =
              this.colours[colourCounter];
            tempDatasets[split[1] + "." + split[2]].pointBackgroundColor =
              "#000000";
          }
          // add the data
          for (let keyII in this.chartIndicatorValues) {
            let splitII = keyII.split(".");
            if (split[1] + "." + split[2] === splitII[1] + "." + splitII[2]) {
              if (keyII.includes("*")) {
                indicatorValue = this.chartIndicatorValues[keyII + ".rate"];
              } else {
                indicatorValue = this.chartIndicatorValues[keyII];
              }
              if (indicatorValue) {
                indicatorValue = indicatorValue.replace(/,/g, "");
              }
              if (typeof indicatorValue !== "undefined") {
                tempDatasets[split[1] + "." + split[2]].data.push(
                  indicatorValue,
                );
              }
            }
          }
          colourCounter++;
          // Go back to first colour if out of colours
          colourCounter %= this.colours.length;
        }
      }
      this.chartConfig = {
        canvasID: this.item.chartRef,
        labels: Object.values(tempLabels),
        datasets: Object.values(tempDatasets),
        title: !this.item.chartDescription
          ? ""
          : this.item.chartDescription.replace("<p>", "").replace("</p>", ""),
        chartYLabel: this.item.chartYLabel,
      };

      // Remove all elements apart from first
      if (!this.item.showComparators) {
        this.chartConfig.labels.splice(1);
        for (let i = 0; i < this.chartConfig.datasets.length; i++) {
          this.chartConfig.datasets[i].data.splice(1);
        }
      }
    },
    invertedHTMLSyncChartConfig() {
      // set these
      var split = [];
      var tempLabels = [];
      var tempDatasets = [];
      var tempIndicatorValueKeys = [];
      var colourCounter = 0;
      var indicatorValue;

      // sort out the labels
      for (var key in this.chartIndicatorValues) {
        // ignore null values
        if (!this.chartIndicatorValues[key]) {
          continue;
        }

        split = key.split(".");

        // Find the date coverage text for the indicator code + date
        for (var indicatorIndex in this.item.indicators) {
          if (
            this.item.indicators[indicatorIndex].indicatorCode == split[1] &&
            this.item.indicators[indicatorIndex].date == split[2]
          ) {
            var dateCoverageText =
              this.item.indicators[indicatorIndex].dateCoverageText;
          }
        }

        if (typeof tempLabels[split[1] + "." + split[2]] === "undefined") {
          if (this.item.timeSeries) {
            tempLabels[split[1] + "." + split[2]] = dateCoverageText;
          } else {
            tempLabels[split[1] + "." + split[2]] =
              split[3] + " - " + dateCoverageText;
          }
          tempIndicatorValueKeys.push(
            split[1] + "." + split[2] + "." + split[3],
          );
        }
      }
      // sort out the datasets
      for (key in this.chartIndicatorValues) {
        split = key.split(".");
        if (typeof tempDatasets[split[0]] === "undefined") {
          tempDatasets[split[0]] = {
            label: this.comparitorName(split[0]),
            backgroundColor: this.colours[colourCounter],
            data: [],
          };
          if (this.item.chartType === "Line") {
            tempDatasets[split[0]].backgroundColor = "transparent";
            tempDatasets[split[0]]["borderColor"] = this.colours[colourCounter];
            tempDatasets[split[0]]["pointBackgroundColor"] = "#000000";
            tempDatasets[split[0]]["tension"] = 0.2; // adjust this to make the lines more curvy (the lower the less curvy)
          }
          // add the data
          for (let i = 0; i < tempIndicatorValueKeys.length; i++) {
            // dynamic rate?
            if (tempIndicatorValueKeys[i].includes("*")) {
              indicatorValue =
                this.chartIndicatorValues[
                  split[0] + "." + tempIndicatorValueKeys[i] + ".rate"
                ];
            } else {
              indicatorValue =
                this.chartIndicatorValues[
                  split[0] + "." + tempIndicatorValueKeys[i]
                ];
            }
            if (indicatorValue) {
              indicatorValue = indicatorValue.replace(/,/g, "");
            }
            tempDatasets[split[0]].data.push(indicatorValue);
          }
          colourCounter++;
          // Go back to first colour if out of colours
          colourCounter %= this.colours.length;
        }
      }
      this.chartConfig = {
        canvasID: this.item.chartRef,
        labels: Object.values(tempLabels),
        datasets: Object.values(tempDatasets),
        title: !this.item.chartDescription
          ? ""
          : this.item.chartDescription.replace("<p>", "").replace("</p>", ""),
        chartYLabel: this.item.chartYLabel,
      };

      // Remove all elements apart from first
      if (!this.item.showComparators) {
        this.chartConfig.datasets.splice(1);
      }
    },
    comparitorName(comparator) {
      for (let i = 0; i < this.comparatorsList.length; i++) {
        if (this.comparatorsList[i].area_code === comparator) {
          return this.comparatorsList[i].area_name;
        }
      }
    },
    setDataErrorAlert(canvasID) {
      this.dataErrorCanvasID = canvasID;
    },
  },
  watch: {
    // indicatorValues: {
    //   handler() {
    //     this.buildChartConfig();
    //   },
    //   deep: true,
    // },
    load: {
      handler() {
        if (this.load) {
          this.setChartIndicatorValues();
          this.buildChartConfig();
          this.displayChart = true;
        }
      },
      immediate: true,
    },
  },
};
</script>

<style scoped></style>
