<template>
  <v-row :style="'height: ' + (height - 50) + 'px;'">
    <v-col xs="6" sm="8" md="8" xl="9" xxl="10" class="pr-0">
      <div id="pano"></div>

      <div
        ref="mapCanvas"
        id="mapCanvas"
        class="map-canvas"
        style="width: 100%; height: 100%"
      ></div>
      <v-snackbar
        v-model="snackBar.show"
        :timeout="snackBar.timeout"
        :color="snackBar.colour"
        close-on-back
        rounded="pill"
        class="discoveryToolSnackbar"
        :style="{ marginRight: mapSidebarWidth + 'px' }"
      >
        {{ snackBar.message }}
      </v-snackbar>
    </v-col>
    <v-col ref="mapSidebar" xs="6" sm="4" md="4" xl="3" xxl="2" class="px-0">
      <DiscoveryToolMapSidebar
        :viewInfo="viewInfo"
        :areaMouseOverInfo="areaMouseOverInfo"
        :colourScheme="colourScheme"
        :lockedView="lockedView"
        :indicators="indicators"
        :selectedLAorCLA="selectedLAorCLA"
        v-model:selectedIndicatorProp="selectedIndicator"
        v-model:areasOfInterestProp="areasOfInterest"
        :loadingIndicators="loadingIndicators"
        :showDataOutsideBoundaries="showDataOutsideBoundaries"
        @closeMap="closeDialog"
        @selectedDataLevelChanged="handleDataLevelSelected"
        @lockViewChanged="handleLockViewChanged"
        @changeIndicator="updateSelectedIndicator"
        @updatePolygonWithAreasOfInterest="updatePolygonWithAreasOfInterest"
        @zoomToAreaOfInterest="zoomToAreaOfInterest"
        @showDataOutsideBoundaries="handleDataBoundaryViewChanged"
        @showOrHideAreaOfInterestOnMap="showOrHideAreaOfInterestOnMap"
      />
    </v-col>
  </v-row>
</template>

<script>
/* global google */

// store map here so vue doesn't wrap it in a proxy - Google doesn't like that.
import { loadGoogleMaps } from "@/mixins/LoadGoogleMaps";
import { useDisplay } from "vuetify";
import { toRaw } from "vue";
import DiscoveryToolMapSidebar from "@/components/DiscoveryToolMapSidebar.vue";

let map = null;

export default {
  name: "DiscoveryToolMap",
  components: { DiscoveryToolMapSidebar },
  data: () => ({
    height: useDisplay().height,
    mapSidebarWidth: 0,
    selectedLAorCLA: [],
    center: { lat: 54.36, lng: -2.59 },
    zoom: 6,
    previousZoom: 6,
    areasOfInterest: {
      disadvantaged: [],
      others: [],
    },
    clientBoundary: [],
    polygonObjects: [],
    viewInfo: {
      viewportInfo: {},
      quintiles: { q1_min: "loading" },
      indicatorInfo: {
        id: null,
      },
      numeratorInfo: {},
    },
    indicators: [],
    loadingIndicators: true,
    selectedIndicator: {},
    zoomAreaLevels: {
      1: 6,
      2: 6,
      3: 6,
      4: 6,
      5: 6,
      6: 6,
      7: 6,
      8: 6, // LA
      9: 5,
      10: 5, //Ward
      11: 2, // MSOA
      12: 2,
      13: 1, // LSOA
      14: 1,
      15: 1,
      16: 1,
      17: 1,
      18: 1,
      19: 1,
      20: 1,
      21: 1,
      22: 1,
    },
    selectedDataLevel: null,
    previousDataLevel: null,
    lockedView: false,
    areaMouseOverInfo: {
      areaInfo: null,
      freeze: false,
    },
    colourScheme: [],
    currentDataLevel: null,
    latestQuintileLevel: 99,
    latestIndicatorId: 0,
    polygonsWithDataset: null,
    idleTimeout: null,
    hotspotFlag: "all",
    mapBoundariesGeoJson: [],
    showDataOutsideBoundaries: false,
    snackBar: {
      show: false,
      message: "",
      timeout: 4000,
      colour: "primary",
    },
    fetchingAreaGeometrySelectedOnMap: false,
  }),
  mounted() {
    // load map
    loadGoogleMaps(this.center, this.zoom).then((loadedMap) => {
      map = loadedMap;

      this.mapSetup();
      this.getBoundaries();
    });

    //set data level to lsoa and lock view on page load
    this.selectedDataLevel = 1;
    this.setSelectedDataLevel();
    this.lockedView = true;
  },
  methods: {
    closeDialog() {
      this.emit.emit("systemBusy", false);
      this.$router.push("/discovery-tool");
      // clear out the map
      this.selectedDataLevel = null;
      this.selectedIndicator = {};
      this.indicators = [];
      this.viewInfo = {
        viewportInfo: {},
        quintiles: { q1_min: "loading" },
        indicatorInfo: {
          id: null,
        },
        numeratorInfo: {},
      };
      this.lockedView = false;
      this.showDataOutsideBoundaries = false;
      this.mapBoundariesGeoJson = [];
      this.clientBoundary.forEach((polygon) => {
        toRaw(polygon).setMap(null);
      });
      this.clientBoundary = [];
      this.polygonObjects.forEach((polygon) => {
        toRaw(polygon).setMap(null);
      });
      this.polygonObjects = [];
    },
    getIndicators() {
      this.loadingIndicators = true;
      this.$axios
        .get("/get-indicators-discovery-tool")
        .then((response) => {
          // handle success
          this.indicators = response.data;
          this.selectedIndicator = JSON.parse(
            JSON.stringify(this.indicators[0]),
          );
          this.viewInfo.indicatorInfo.id = this.selectedIndicator.id;
          this.viewInfo.viewportInfo.ind_id = this.selectedIndicator.id;
        })
        .catch((error) => {
          // handle error
          console.error(error);
        })
        .finally(() => {
          this.loadingIndicators = false;
        });
    },
    mapSetup() {
      this.zoomToDefaultView();

      // add listener to see if anything changes
      map.addListener(
        "zoom_changed",
        () => {
          this.deBounce({ event: "zoom" });
        },
        { passive: true },
      );

      map.addListener(
        "drag",
        () => {
          this.deBounce({ event: "drag" });
        },
        { passive: true },
      );

      // set up all the view details
      this.viewInfo.viewportInfo = {
        dataLevel: this.viewInfo.viewportInfo.dataLevel
          ? this.viewInfo.viewportInfo.dataLevel
          : 6,
        xmin: 0,
        ymin: 0,
        xmax: 0,
        ymax: 0,
        ind_id: this.viewInfo.viewportInfo.ind_id || null,
      };

      // get the viewport to start
      this.deBounce();
    },
    deBounce({ event = "none" } = {}) {
      /**
       * force update in updateViewDetails if the map has been dragged
       */
      const forceUpdate = event === "drag" ? true : false;
      // clear this
      clearTimeout(this.idleTimeout);
      // start the countdown again!
      this.idleTimeout = setTimeout(
        function () {
          this.updateViewDetails({ forceUpdate: forceUpdate });
        }.bind(this),
        1250,
      );
    },
    updateViewDetails({ forceUpdate = false } = {}) {
      //PostGis docs - ST_MakeEnvelope(float xmin, float ymin, float xmax, float ymax, integer srid=unknown)
      // update view info
      if (map) {
        this.viewInfo.viewportInfo.xmin = map.getBounds().getSouthWest().lng();
        this.viewInfo.viewportInfo.ymin = map.getBounds().getSouthWest().lat();
        this.viewInfo.viewportInfo.xmax = map.getBounds().getNorthEast().lng();
        this.viewInfo.viewportInfo.ymax = map.getBounds().getNorthEast().lat();
      }

      if (this.selectedDataLevel) {
        //data level selected from dropdown
        this.setSelectedDataLevel();
      } else {
        //map zoom data level
        this.setZoomDataLevel();
      }

      //nothing further if data is same
      if (!this.viewDataChanged() && !forceUpdate) {
        return;
      }

      // call this
      if (this.viewInfo.indicatorInfo.id) {
        this.getDataCount();
        this.getPolygonsWithDataset();
      }
    },
    /** whether need to fetch figures and visuals (save some backend calls) */
    viewDataChanged() {
      /**
       * Do not need to fetch indicator visualisations and quintile figures if:
       * i) view is locked (data level fixed) and zooming closer
       * ii) view is unlocked, zooming closer and new data level is same as previous zoom
       */
      let response = true;

      if (
        (this.lockedView && map.getZoom() >= this.previousZoom) ||
        (!this.lockedView &&
          map.getZoom() >= this.previousZoom &&
          this.viewInfo.viewportInfo.dataLevel === this.previousDataLevel)
      ) {
        response = false;
      }

      this.previousZoom = map.getZoom(); //update previous zoom value with current zoom level

      return response;
    },
    /** set data level based on selected level */
    setSelectedDataLevel() {
      //set data level to selected and reset flag
      if (!this.lockedView && this.selectedDataLevel) {
        this.previousDataLevel = this.viewInfo.viewportInfo.dataLevel;
        this.viewInfo.viewportInfo.dataLevel = this.selectedDataLevel;
        this.selectedDataLevel = null;
      }
    },
    /** set data level based on map zoom */
    setZoomDataLevel() {
      this.previousDataLevel = this.viewInfo.viewportInfo.dataLevel;
      if (
        //not locked & zoom is below data lowest level - set data level to lowest
        !this.lockedView &&
        this.viewInfo.indicatorInfo.lowest_show_level >
          this.zoomAreaLevels[map.getZoom()]
      ) {
        this.viewInfo.viewportInfo.dataLevel =
          this.viewInfo.indicatorInfo.lowest_show_level;
      } else {
        //not locked - set data level to zoom
        if (!this.lockedView) {
          this.viewInfo.viewportInfo.dataLevel =
            this.zoomAreaLevels[map.getZoom()];
        }
      }
    },
    updateSelectedIndicator(value) {
      this.selectedIndicator = value;
      this.viewInfo.viewportInfo.ind_id = value.id;
      if (this.viewInfo.viewportInfo.ind_id) {
        this.getIndicatorDetails();
      }
    },
    getIndicatorDetails() {
      this.emit.emit("systemBusy", true);
      this.currentDataLevel = null;
      this.viewInfo.quintiles = {
        q1_min: "loading",
      };
      this.$axios
        .get("/standard-metadata-map/" + this.selectedIndicator.id)
        .then(
          function (response) {
            // handle success
            this.viewInfo.indicatorInfo = response.data[0];
            this.viewInfo.numeratorInfo = response.data[1];
            this.colourScheme = response.data[0].colour_scheme;
            this.getDataCount();
            this.getPolygonsWithDataset();
          }.bind(this),
        )
        .catch(
          function (error) {
            // handle error
            console.error(error);
            this.emit.emit("systemMessage", {
              message: error.response.data.message,
              title: "Failed to fetch details about the selected indicator",
              timeout: 5000,
              colour: "error",
            });
            this.emit.emit("systemBusy", false);
          }.bind(this),
        );
    },
    getPolygonsWithDataset() {
      this.emit.emit("systemBusy", true);
      // add the the hotspot flag go determine what data to get back
      this.viewInfo.viewportInfo.hotspotFlag = this.hotspotFlag;

      //add displayed boundaries/custom areas visualisations flag into backend data
      this.viewInfo.viewportInfo.mapBoundariesGeoJson =
        this.mapBoundariesGeoJson;

      this.viewInfo.viewportInfo.showDataOutsideBoundaries =
        this.showDataOutsideBoundaries;

      // set the colours
      var colors = this.colourScheme;
      var color;

      this.$axios
        .post("/data-geometries", this.viewInfo.viewportInfo)
        .then(
          function (response) {
            // handle success
            this.polygonsWithDataset = response.data;

            let features = map.data;
            features.forEach(function (feature) {
              features.remove(feature);
            });
            if (this.polygonsWithDataset.features) {
              map.data.addGeoJson(this.polygonsWithDataset);
              this.addDataLayerListeners();
              map.data.setStyle(function (feature) {
                var quintile = feature.getProperty("quintile");
                switch (quintile) {
                  case 1:
                    color = "#" + colors[0];
                    break;
                  case 2:
                    color = "#" + colors[1];
                    break;
                  case 3:
                    color = "#" + colors[2];
                    break;
                  case 4:
                    color = "#" + colors[3];
                    break;
                  case 5:
                    color = "#" + colors[4];
                    break;
                  default:
                    color = "#" + colors[4];
                }
                return {
                  fillColor: color,
                  fillOpacity: 0.5,
                  strokeColor: "#FFFFFF",
                  strokeWeight: 0.5,
                };
              });
            }
            this.emit.emit("systemBusy", false);
          }.bind(this),
        )
        .catch(
          function (error) {
            this.emit.emit("systemBusy", false);
            // handle error
            this.emit.emit("systemMessage", {
              message: error.response.data.message,
              title: "Failed to load data",
              timeout: 4000,
              colour: "error",
            });
            console.error(error);
          }.bind(this),
        );
    },
    openSnackbar(message, timeout = 4000, colour = "primary") {
      // get the width of the sidebar
      this.mapSidebarWidth = this.$refs.mapSidebar.$el.offsetWidth;

      // close any open snackbar
      this.snackBar.show = false;

      // open a new one
      this.snackBar.message = message;
      this.snackBar.timeout = timeout;
      this.snackBar.colour = colour;
      this.snackBar.show = true;
    },
    addDataLayerListeners() {
      // add listener to see if anything changes
      // mouse over to show the area & value
      map.data.addListener(
        "mouseover",
        function (event) {
          if (!this.areaMouseOverInfo.freeze) {
            //update the data with the event, which includes the mouse overed feature
            this.areaMouseOverInfo.areaInfo = event;

            // take a note of the feature clicked
            this.mouseFeature = event.feature;

            // up the border as a highlight
            map.data.overrideStyle(this.mouseFeature, {
              strokeWeight: 3.5,
            });
          }
        }.bind(this),
      );

      map.data.addListener(
        "mouseout",
        function () {
          //update the data with the event, which includes the mouse overed feature
          if (!this.areaMouseOverInfo.freeze) {
            this.areaMouseOverInfo.areaInfo = null;

            // reset border
            map.data.overrideStyle(this.mouseFeature, {
              strokeWeight: 0.5,
            });
          }
        }.bind(this),
      );

      // click to add area to the areas of interest
      map.data.addListener(
        "click",
        async function (event) {
          if (!event.alreadyCalled_) {
            event.alreadyCalled_ = true;

            if (this.fetchingAreaGeometrySelectedOnMap) return;

            // if it's not LSOA level, don't do anything
            if (this.viewInfo.viewportInfo.dataLevel !== 1) {
              this.openSnackbar(
                "You can only select areas at LSOA level",
                4000,
                "error",
              );
              return;
            }

            const areaID = event.feature.getProperty("area_id");
            let area = null;
            // get the boundaries of the area user clicked on
            let boundaries = this.polygonsWithDataset.features.find(
              (f) => f.properties.area_id === areaID,
            );

            // is it already on the list?
            for (let key in this.areasOfInterest) {
              for (let j = 0; j < this.areasOfInterest[key].length; j++) {
                if (this.areasOfInterest[key][j].id === areaID) {
                  area = JSON.parse(
                    JSON.stringify(this.areasOfInterest[key][j]),
                  );
                }
              }
            }

            if (!area) {
              const response = await this.getSelectedAreaOnMap(
                areaID,
                boundaries,
              );

              if (response.error) {
                this.openSnackbar(response.error, 4000, "error");
                return;
              }
              area = JSON.parse(JSON.stringify(response));

              // add it to the list
              const type = area.is_double_disadvantage
                ? "disadvantaged"
                : "others";
              this.areasOfInterest[type].push(area);
            }

            const type = area.is_double_disadvantage
              ? "disadvantaged"
              : "others";
            area.selected = !area.selected;

            // update the checkbox
            this.areasOfInterest[type].forEach((a) => {
              if (a.id === areaID) a.selected = area.selected;
            });

            if (area.selected && !area.boundary_fetched) {
              await this.updatePolygonWithAreasOfInterest(boundaries);
            } else {
              // show or hide the boundary
              await this.showOrHideAreaOfInterestOnMap(area);
            }

            if (area.selected) {
              this.openSnackbar("Area of interest selected", 4000, "#265928");
            } else {
              this.openSnackbar(
                "Area of interest selection removed",
                4000,
                "#265928",
              );
            }
            this.fetchingAreaGeometrySelectedOnMap = false;
          }
        }.bind(this),
      );
    },
    async getSelectedAreaOnMap(areaID) {
      // loading snackbar
      this.openSnackbar("Loading area geometry...", -1, "#0E5B99");
      this.fetchingAreaGeometrySelectedOnMap = true;

      try {
        const response = await this.$axios.post("/get-area-selected-on-map", {
          parent_area_ids: this.selectedLAorCLA,
          selected_area_id: areaID,
        });
        return response.data;
      } catch (error) {
        console.error(error);

        return {
          error: error.response.data.message,
        };
      } finally {
        this.fetchingAreaGeometrySelectedOnMap = false;
      }
    },
    getDataCount() {
      this.$axios
        .post("/get-data-count", this.viewInfo.viewportInfo)
        .then(
          function (response) {
            // handle success
            if (response.data > 0) {
              // call this for the information panel
              this.buildIndicatorQuintiles();
            } else {
              this.viewInfo.quintiles.q1_min = null;
            }
          }.bind(this),
        )
        .catch(
          function (error) {
            // handle error
            this.emit.emit("systemMessage", {
              message: error.response.data.message,
              title: "Failed to load data",
              timeout: 4000,
              colour: "error",
            });
            console.error(error);
          }.bind(this),
        );
    },
    buildIndicatorQuintiles(forceUpdate = true) {
      if (
        this.viewInfo.viewportInfo.dataLevel != this.latestQuintileLevel ||
        this.viewInfo.indicatorInfo.id != this.latestIndicatorId ||
        forceUpdate
      ) {
        this.viewInfo.quintiles = {
          q1_min: "loading",
        };

        this.$axios
          .get(
            "/standard-data-quintile-range/" +
              this.viewInfo.viewportInfo.ind_id +
              "/" +
              this.viewInfo.viewportInfo.dataLevel +
              "/" +
              this.viewInfo.viewportInfo.hotspotFlag,
          )
          .then((response) => {
            // handle success
            this.viewInfo.quintiles = response.data;
          })
          .catch(
            function (error) {
              // handle error
              this.emit.emit("systemMessage", {
                message: error.response.data.message,
                title: "Failed to build the quintiles",
                timeout: 5000,
                colour: "error",
              });
              console.error(error);
            }.bind(this),
          );

        // update our latest fetched quintile info
        this.latestQuintileLevel = this.viewInfo.viewportInfo.dataLevel;
        this.latestIndicatorId = this.viewInfo.indicatorInfo.id;
      }
    },
    handleDataLevelSelected(newValue) {
      this.selectedDataLevel = newValue;
      if (!this.lockedView) {
        this.updateViewDetails();
      }
    },
    handleLockViewChanged(newValue) {
      this.lockedView = newValue;
      if (!this.lockedView) {
        this.updateViewDetails();
      }
    },
    handleDataBoundaryViewChanged(newValue) {
      this.showDataOutsideBoundaries = newValue;
      this.updateViewDetails({ forceUpdate: true });
    },
    async getBoundaries() {
      this.emit.emit("systemBusy", true);

      // get passed area IDs from the URL
      this.selectedLAorCLA = this.$route.params.parentAreaIDs.split("-");

      // first clear out anything that's there
      if (this.clientBoundary.length > 0) {
        this.clientBoundary.forEach((polygon) => {
          toRaw(polygon).setMap(null);
        });

        this.clientBoundary = [];
      }
      const { Polygon } = await google.maps.importLibrary("maps");

      this.$axios
        .post("/get-boundaries-discovery-tool", {
          area_ids: this.selectedLAorCLA,
        })
        .then((response) => {
          // handle success
          if (response.data.boundary) {
            const boundary = JSON.parse(response.data.boundary);

            //add client boundary to list of boundaries currently displayed
            this.mapBoundariesGeoJson.push(JSON.stringify(boundary));

            // build an array of polygons, can be one, or many for a multiplygon
            let polygons = [];

            if (boundary.type === "Polygon") {
              // Polygon
              const googleMapsPaths = boundary.coordinates.map((ring) =>
                ring.map((coordinate) => {
                  return { lat: coordinate[1], lng: coordinate[0] };
                }),
              );

              polygons.push(
                new Polygon({
                  map,
                  paths: googleMapsPaths,
                  fillOpacity: 0,
                  strokeWeight: 4,
                  strokeColor: response.data.boundary_colour,
                  zIndex: 10,
                  clickable: false,
                }),
              );
              this.clientBoundary = polygons;
            } else {
              // `MultiPolygon`
              const multiCoordinates = boundary.coordinates;
              multiCoordinates.forEach((coords) => {
                const googleMapsPaths = coords.map((coord) => {
                  return coord.map((subCoord) => {
                    return { lat: subCoord[1], lng: subCoord[0] };
                  });
                });

                polygons.push(
                  new Polygon({
                    map,
                    paths: googleMapsPaths,
                    fillOpacity: 0,
                    strokeWeight: 4,
                    strokeColor: response.data.boundary_colour,
                    zIndex: 10,
                    clickable: false,
                  }),
                );
                this.clientBoundary = polygons;
              });
            }

            this.zoomToSelectedLAorCLA().then(() => {
              // once we've got the boundaries and zoomed in let's display the data
              setTimeout(() => {
                this.getIndicators();
              }, 1500);
            });
          }
        })
        .catch((error) => {
          // handle error
          this.emit.emit("systemMessage", {
            message: error.response.data.message,
            title: "Failed to fetch boundaries for the selected areas",
            timeout: 5000,
            colour: "error",
          });
          console.error(error);
        });
    },
    async updatePolygonWithAreasOfInterest(
      feature,
      boundary_color = "#FF0000",
    ) {
      const { Polygon } = await google.maps.importLibrary("maps");

      const geometry = feature.geometry;
      const geometryType = geometry.type;
      const areaId = feature.properties.area_id;

      if (geometryType === "Polygon" || geometryType === "MultiPolygon") {
        if (geometryType === "Polygon") {
          // Polygon with possible holes
          const googleMapsPaths = geometry.coordinates.map((ring) =>
            ring.map((coord) => {
              return { lat: coord[1], lng: coord[0] };
            }),
          );

          const googleMapsPolygon = new Polygon({
            map,
            paths: googleMapsPaths,
            fillOpacity: 0,
            strokeWeight: 3,
            strokeColor: boundary_color,
            zIndex: 10,
            clickable: false,
            visible: false,
          });

          // add custom props
          googleMapsPolygon.area_id = areaId;
          this.polygonObjects.push(googleMapsPolygon);
        } else if (geometryType === "MultiPolygon") {
          // MultiPolygon
          const multiCoordinates = geometry.coordinates;
          multiCoordinates.forEach((coords) => {
            const googleMapsPaths = coords.map((coord) => {
              return coord.map((subCoord) => {
                return { lat: subCoord[1], lng: subCoord[0] };
              });
            });

            const googleMapsPolygon = new Polygon({
              map,
              paths: googleMapsPaths,
              fillOpacity: 0,
              strokeWeight: 3,
              strokeColor: boundary_color,
              zIndex: 10,
              clickable: false,
              visible: false,
            });

            // add custom props
            googleMapsPolygon.area_id = areaId;
            this.polygonObjects.push(googleMapsPolygon);
          });
        }

        // update the area of interest to say we've fetched the boundary
        for (let key in this.areasOfInterest) {
          for (let j = 0; j < this.areasOfInterest[key].length; j++) {
            if (this.areasOfInterest[key][j].id === areaId) {
              this.areasOfInterest[key][j].boundary_fetched = true;
              break;
            }
          }
        }

        // load it on the map
        this.showOrHideAreaOfInterestOnMap({ id: areaId, selected: true });
      }
    },
    async zoomToSelectedLAorCLA() {
      // gather up all the bounds of any loaded polys
      var bounds = new google.maps.LatLngBounds();

      // iterate over each polygon in this.polygonObjects and extend the bounds
      this.clientBoundary.forEach(function (polygon) {
        polygon.getPath().forEach(function (latLng) {
          bounds.extend(latLng);
        });
      });

      // fit the map to the bounds to show all the polygons
      map.fitBounds(bounds);
    },
    zoomToDefaultView() {
      // get the clients default view
      const defaultZoom = this.$store.getters.customClientConfig.default_zoom;
      const defaultLat = this.$store.getters.customClientConfig.default_lat;
      const defaultLng = this.$store.getters.customClientConfig.default_lng;

      // if they have them ALL, set the map up to that
      if (defaultZoom && defaultLat && defaultLng) {
        if (!isNaN(defaultLat) && !isNaN(defaultLng)) {
          map.setZoom(defaultZoom);
          map.setCenter({
            lat: parseFloat(defaultLat),
            lng: parseFloat(defaultLng),
          });
        } else {
          console.error("Invalid coordinates for default view");
        }
      }
    },
    zoomToAreaOfInterest(area) {
      const bounds = new google.maps.LatLngBounds();
      this.polygonObjects.forEach((polygon) => {
        if (polygon.area_id === area.id) {
          polygon.getPath().forEach((latLng) => {
            bounds.extend(latLng);
          });
        }
      });
      map.fitBounds(bounds);
    },
    // toggle the visibility of the area with red or remove it
    showOrHideAreaOfInterestOnMap(area) {
      this.polygonObjects.forEach((polygon) => {
        if (polygon.area_id === area.id) {
          toRaw(polygon).setVisible(area.selected);
        }
      });
    },
  },
};
</script>

<style scoped></style>
<style>
/* some styling for the vuetify's snackbar */
.discoveryToolSnackbar .v-snackbar__content {
  text-align: center !important;
}
.discoveryToolSnackbar .v-snackbar__wrapper {
  min-width: unset !important;
}
</style>
