<template>
  <v-container fluid pa-5 fill-height>
    <!-- the parent card/tile -->
    <v-card min-height="95%">
      <v-card-title class="text-left"
        ><h1 class="h1">Report Themes Manager</h1></v-card-title
      >
      <v-card-actions> </v-card-actions>
      <v-card-actions>
        <!-- dropdown card/tile -->
        <v-card-text
          tile
          width="100%"
          pa-5
          :style="'height:' + this.height + 'px; overflow: auto;'"
        >
          <v-row>
            <v-col>
              <v-autocomplete
                id="themes-list"
                style="width: 100%; max-width: 800px !important"
                v-model="selectedReport"
                ref="selectReportTheme"
                :items="allReportThemes"
                v-model:search="searchTheme"
                item-title="name"
                item-value="id"
                return-object
                :no-filter="disableVuetifyFilter"
                label="View / Edit Report Theme"
                variant="outlined"
                density="compact"
                rounded="0"
                align-center
                :autofocus="true"
                :loading="loadingFilteredThemes"
              >
                <template
                  v-if="showFilteredThemesByClient && !loadingFilteredThemes"
                  v-slot:item
                >
                  <v-list density="compact">
                    <v-list-subheader class="text-h6"
                      >{{
                        !allReportThemes[0]["Bespoke themes for"].length
                          ? "There are NO "
                          : ""
                      }}Bespoke themes for
                      {{ selectedFilterClient.client_name }}
                    </v-list-subheader>
                    <span v-if="!bespokeFilteredThemes.length"
                      ><v-list-item-title class="ml-4 text-caption"
                        >- No matching search results</v-list-item-title
                      ></span
                    >
                    <span v-else>
                      <v-list-item
                        v-for="item in bespokeFilteredThemes"
                        :key="item.id"
                        @click="selectedReport = item"
                      >
                        <v-list-item-title class="ml-3">{{
                          item.name
                        }}</v-list-item-title>
                      </v-list-item>
                    </span>

                    <v-list-subheader class="text-h6 mt-5"
                      >{{
                        !allReportThemes[0]["Hidden themes from"].length
                          ? "There are NO "
                          : ""
                      }}
                      Themes hidden from
                      {{ selectedFilterClient.client_name }}
                    </v-list-subheader>
                    <span v-if="!hiddenFilteredThemes.length"
                      ><v-list-item-title class="ml-4 text-caption"
                        >- No matching search results</v-list-item-title
                      ></span
                    >
                    <span v-else>
                      <v-list-item
                        v-for="item in hiddenFilteredThemes"
                        :key="item.id"
                        @click="selectedReport = item"
                      >
                        <v-list-item-title class="ml-3">{{
                          item.name
                        }}</v-list-item-title>
                      </v-list-item>
                    </span>
                  </v-list>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col>
              <v-autocomplete
                v-model="selectedFilterClient"
                :items="clients"
                :loading="clientFilterSearchLoading"
                v-model:search="searchFilterClient"
                loader-height="2"
                item-title="client_name"
                item-value="id"
                prepend-inner-icon="mdi mdi-filter"
                label="Filter by client"
                placeholder="Start typing to Search"
                return-object
                :no-data-text="
                  clientFilterSearchLoading ? 'Searching' : 'No Results'
                "
                style="max-width: 300px"
                density="compact"
                rounded="0"
                clearable
                variant="outlined"
                class="ml-7"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-spacer
              style="width: 100%; max-width: 669px !important"
            ></v-spacer>
            <span>
              <Create
                @newReportThemeCreated="getReportThemes"
                :schema="fieldSchema"
              />
            </span>
          </v-card-actions>
          <v-card-actions v-if="showPanel">
            <v-row dense>
              <!-- select comparators -->
              <v-col cols="6">
                <v-card tile pa-5>
                  <v-card-subtitle class="mt-0"
                    >Area Comparators (for editing purposes only)
                  </v-card-subtitle>
                  <v-card-text style="max-height: 55vh; overflow: auto">
                    <v-card-actions class="pt-0">
                      <v-select
                        v-model="selectedAreaLevel"
                        :items="areaLevels"
                        item-title="area_level_name"
                        item-value="area_level_name_abr"
                        label="Level Filter"
                        variant="outlined"
                        density="compact"
                        rounded="0"
                        class="width"
                        align-center
                        clearable
                      >
                      </v-select>
                    </v-card-actions>
                    <v-card-actions class="pt-0">
                      <v-select
                        v-model="area"
                        :items="selectedAreaLevelsAreas"
                        item-title="area_name"
                        item-value="area_code"
                        label="Select Area"
                        variant="outlined"
                        density="compact"
                        rounded="0"
                        class="width"
                        align-center
                        clearable
                        return-object
                      >
                      </v-select>
                    </v-card-actions>
                    <v-card-actions class="pt-0">
                      <v-select
                        v-model="selectedComparators"
                        :items="comparatorsList"
                        item-title="area_name"
                        item-value="area_code"
                        label="Comparators"
                        multiple
                        chips
                        hint="Select Comparators (in order of preference)"
                        variant="outlined"
                        density="compact"
                        rounded="0"
                        class="width pt-0"
                        align-center
                        clearable
                      >
                      </v-select>
                    </v-card-actions>
                  </v-card-text>
                  <v-card-actions>
                    <Edit
                      ref="ReportThemeEditor"
                      v-on:updateTheme="upsertReportTheme()"
                      v-on:cancelEdit="reset()"
                      v-on:clone="cloned"
                      :selectedComparators="selectedComparators"
                      :comparatorsList="comparatorsList"
                    />
                    <DeleteReportTheme
                      v-on:getThemeList="getReportThemes"
                      v-on:clearPanel="clearPanel"
                      :reportThemeObject="reportThemeObject"
                    />
                    <v-spacer></v-spacer>
                    <v-btn
                      color="success"
                      rounded="0"
                      variant="elevated"
                      @click="upsertReportTheme()"
                      aria-label="Save Changes"
                    >
                      Save Changes
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
              <!-- report details -->
              <v-col cols="6">
                <v-card tile pa-5>
                  <v-card-text
                    :style="
                      'max-height: ' + (height - 317) + 'px; overflow: auto;'
                    "
                  >
                    <v-form ref="form" v-model="validation" lazy-validation>
                      <v-row>
                        <v-col
                          cols="12"
                          sm="12"
                          md="6"
                          v-for="(item, key) in fieldSchema"
                          :key="key"
                          class="pb-0 mb-0"
                        >
                          <Field
                            v-if="item.show"
                            :schema="fieldSchema[key]"
                            v-model:value="reportThemeObject[key]"
                          />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-card-actions>
                            <v-select
                              v-tooltip="
                                'Assign this theme to a client for the Discovery Tool Summary Report'
                              "
                              class=""
                              max-width="800px"
                              label="Assign this theme as Discovery Summary"
                              v-model="assignDiscoveryThemeItem"
                              return-object
                              :items="discoveryItems"
                              item-value="client_id"
                              item-title="client_name"
                              density="compact"
                              rounded="0"
                              variant="outlined"
                              filled
                              :disabled="discoveryLoading"
                              :loading="discoveryLoading"
                            ></v-select>
                            <v-select
                              v-tooltip="
                                'Go here to review and/or remove currently assigned Discovery Tool Summary Report themes for clients'
                              "
                              class="fields pa-0 ma-0 ml-4"
                              max-width="50%"
                              label="Current Discovery Theme Assignment"
                              v-model="currentSelectedDiscoveryItems"
                              return-object
                              :items="filteredDiscoveryItems"
                              item-value="id"
                              item-title="displayText"
                              multiple
                              density="compact"
                              rounded="0"
                              variant="outlined"
                              filled
                              :disabled="discoveryLoading"
                              :loading="discoveryLoading"
                            >
                              <template v-slot:selection="{ item, index }">
                                <v-chip v-if="index < 1">
                                  <span>{{ item.title }}</span>
                                </v-chip>
                                <span
                                  v-if="index === 1"
                                  class="text-grey text-caption align-self-center"
                                >
                                  (+{{
                                    currentSelectedDiscoveryItems.length - 1
                                  }}
                                  others)
                                </span>
                              </template>
                            </v-select>
                          </v-card-actions>
                          <v-card-actions>
                            <v-autocomplete
                              id="searchClients"
                              v-model="selectedClientBespoke"
                              :items="clients"
                              :loading="clientSearchLoadingBespoke"
                              v-model:search="searchClientBespoke"
                              loader-height="2"
                              item-title="client_name"
                              item-value="id"
                              label="Bespoke theme for a client"
                              placeholder="Start typing to Search"
                              return-object
                              :no-data-text="
                                clientSearchLoadingBespoke
                                  ? 'Searching'
                                  : 'No Results'
                              "
                              style="max-width: 800px"
                              density="compact"
                              rounded="0"
                              clearable
                              variant="outlined"
                              class=""
                            ></v-autocomplete>
                            <v-select
                              no-data-text="No clients selected"
                              :loading="removeClientLoading"
                              v-model="removeBespokeClient"
                              :items="bespokeThemeAccess"
                              filled
                              variant="outlined"
                              class="fields pa-0 ma-0 ml-4"
                              density="compact"
                              rounded="0"
                              label="Clients with Access to this theme (select to remove)"
                              item-title="client_name"
                              item-value="id"
                              return-object
                            ></v-select>
                          </v-card-actions>
                          <v-card-actions>
                            <v-autocomplete
                              id="searchHideClients"
                              v-model="selectedClientHide"
                              :items="clients"
                              :loading="clientsHideSearchLoading"
                              v-model:search="searchClientHide"
                              loader-height="2"
                              item-title="client_name"
                              item-value="id"
                              label="Hide this theme from a client"
                              placeholder="Start typing to Search"
                              return-object
                              :no-data-text="
                                clientsHideSearchLoading
                                  ? 'Searching'
                                  : 'No Results'
                              "
                              style="max-width: 800px"
                              density="compact"
                              rounded="0"
                              clearable
                              variant="outlined"
                              class=""
                            ></v-autocomplete>
                            <v-select
                              no-data-text="No clients selected"
                              :loading="removeHiddenFromClientLoading"
                              v-model="removeHiddenFromClient"
                              :items="hideThemeClientList"
                              filled
                              variant="outlined"
                              class="fields pa-0 ma-0 ml-4"
                              density="compact"
                              rounded="0"
                              label="Clients theme hidden from (select to remove)"
                              item-title="client.client_name"
                              item-value="id"
                              return-object
                            ></v-select>
                          </v-card-actions>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card-text>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import Field from "@/components/Fields.vue";
import Create from "@/components/ReportThemeCreate";
import Edit from "@/components/ReportThemeEditor";
import DeleteReportTheme from "@/components/DeleteReportTheme.vue";
import RTShema from "@/schemas/ReportTheme";
import { useDisplay } from "vuetify";
import { addTargetToLinks } from "@/mixins/AddTargetToLinks";

export default {
  name: "NAME",
  data: () => ({
    discoveryItems: [],
    discoveryLoading: false,
    assignDiscoveryThemeItem: [],
    addTargetToLinks: addTargetToLinks,
    height: useDisplay().height,
    areaLevels: [],
    selectedAreaLevel: null,
    selectedAreaLevelsAreas: [],
    area: null,
    selectedComparators: [],
    comparatorsList: [],
    fieldSchema: RTShema,
    allReportThemes: [],
    cloneAllReportThemes: [],
    selectedReport: { name: null, id: null },
    showPanel: false,
    validation: false,
    countryForClone: null,
    selectedClientBespoke: null,
    selectedClientHide: null,
    clients: [],
    clientSearchLoadingBespoke: false,
    clientsHideSearchLoading: false,
    searchClientBespoke: null,
    searchClientHide: null,
    searchIteration: null,
    removeBespokeClient: null,
    removeHiddenFromClient: null,
    bespokeThemeAccess: [],
    hideThemeClientList: [],
    removeClientLoading: false,
    removeHiddenFromClientLoading: false,
    searchFilterClient: null,
    selectedFilterClient: null,
    clientFilterSearchLoading: null,
    searchTheme: "",
    disableVuetifyFilter: false,
    loadingFilteredThemes: false,
  }),
  components: {
    DeleteReportTheme,
    Create,
    Field,
    Edit,
  },
  computed: {
    currentSelectedDiscoveryItems: {
      get() {
        return this.filteredDiscoveryItems;
      },
      set(value) {
        this.discoveryItems.forEach((item) => {
          // Check if the current item is in the value
          const isSelected = value.some(
            (selectedItem) => selectedItem.id === item.id,
          );
          // If it's not selected, set theme_id to null
          if (!isSelected && item.id !== null) {
            item.theme_id = null;
            item.update = true;
          }
        });
      },
    },
    filteredDiscoveryItems() {
      // Filter out any items with null theme_id
      return this.discoveryItems
        .filter((item) => item.theme_id !== null)
        .map((item) => ({
          ...item,
          displayText: `${item.client_name} | ${item.theme_name} | ${item.country_name}`,
          isChecked: item.theme_id !== null,
        }));
    },
    reportThemeObject: {
      get() {
        return this.$store.state.reportThemeObject;
      },
      set(value) {
        this.$store.commit("setReportThemeObject", value);
      },
    },
    reportThemeObjectClone: {
      get() {
        return this.$store.state.reportThemeObjectClone;
      },
      set(value) {
        this.$store.commit("setReportThemeObjectClone", value);
      },
    },
    showFilteredThemesByClient() {
      return (
        this.allReportThemes.length &&
        Object.prototype.hasOwnProperty.call(
          this.allReportThemes[0],
          "Bespoke themes for",
        )
      );
    },
    bespokeFilteredThemes() {
      return this.allReportThemes[0]["Bespoke themes for"].filter(
        (theme) => theme.show,
      );
    },
    hiddenFilteredThemes() {
      return this.allReportThemes[0]["Hidden themes from"].filter(
        (theme) => theme.show,
      );
    },
  },
  props: {},
  mounted() {
    // get the discover tool stuff
    this.discoveryAPI();
    if (this.$store.state.config.siteConfig.site_country === "aus") {
      this.fieldSchema.country.selectItems = [
        { text: "Australia", value: "A" },
      ];
    }
    this.getReportThemes();
    this.getAreaLevels();

    // select the areas, depending on the current country
    this.setDefaultComparators();
  },
  methods: {
    setDefaultComparators() {
      switch (
        this.$store.getters.customClientConfig.national_comparator
          .area_code_prefix
      ) {
        case "E":
          this.selectedComparators = ["E06000043", "E92000001", "E12000008"];
          this.comparatorsList = [
            {
              id: 6912,
              area_code: "E06000043",
              area_name: "Brighton and Hove",
            },
            {
              id: 6958,
              area_code: "E92000001",
              area_name: "England",
            },
            {
              id: 6811,
              area_code: "E12000008",
              area_name: "South East",
            },
          ];
          break;
        case "W":
          this.selectedComparators = ["W06000015", "W92000004", "W06000009"];
          this.comparatorsList = [
            {
              id: 6912,
              area_code: "W06000015",
              area_name: "Cardiff",
            },
            {
              id: 6958,
              area_code: "W92000004",
              area_name: "Wales",
            },
            {
              id: 6811,
              area_code: "W06000009",
              area_name: "Pembrokeshire",
            },
          ];
          break;
        case "S":
          this.selectedComparators = ["S12000036", "S92000003", "S12000034"];
          this.comparatorsList = [
            {
              id: 6912,
              area_code: "S12000036",
              area_name: "City of Edinburgh",
            },
            {
              id: 6958,
              area_code: "S92000003",
              area_name: "Scotland",
            },
            {
              id: 6811,
              area_code: "S12000034",
              area_name: "Aberdeenshire",
            },
          ];
          break;
        case "A":
          this.selectedComparators = ["ALGA31000", "ANATAUS", "ASTE4"];
          this.comparatorsList = [
            {
              id: 453635,
              area_code: "ALGA31000",
              area_name: "Brisbane",
            },
            {
              id: 2,
              area_code: "ANATAUS",
              area_name: "Australia",
            },
            {
              id: 453727,
              area_code: "ASTE4",
              area_name: "South Australia",
            },
          ];
          break;
        default:
          console.error("unknown country");
      }
    },
    cloned(clone) {
      this.allReportThemes.unshift(clone);
    },
    getAreaLevelsAreas() {
      this.$axios
        .get("/list-areas-by-level/" + this.selectedAreaLevel)
        .then(
          function (response) {
            // handle success
            this.selectedAreaLevelsAreas = response.data;
            this.emit.emit("systemBusy", false);
          }.bind(this),
        )
        .catch(
          function (error) {
            this.emit.emit("systemBusy", false);
            // handle error
            console.error(error);
            this.emit.emit("systemMessage", {
              message: error.response.data.message,
              title: "Error! Failed to get Area Levels",
              timeout: -1,
              colour: "red",
            });
          }.bind(this),
        );
    },
    getAreaLevels() {
      this.$axios
        .get("/list-area-levels")
        .then(
          function (response) {
            // handle success
            this.areaLevels = response.data;
            this.emit.emit("systemBusy", false);
          }.bind(this),
        )
        .catch(
          function (error) {
            this.emit.emit("systemBusy", false);
            // handle error
            console.error(error);
            this.emit.emit("systemMessage", {
              message: error.response.data.message,
              title: "Error! Failed to get Area Levels",
              timeout: -1,
              colour: "red",
            });
          }.bind(this),
        );
    },
    reset() {
      this.reportThemeObject = this.$cloneDeep(this.reportThemeObjectClone);
    },
    getReportThemes() {
      this.$axios
        .get("/reports-themes")
        .then(
          function (response) {
            // handle success
            this.allReportThemes = response.data;

            // clone the main list, we need this for the filter by client
            this.cloneAllReportThemes = JSON.parse(
              JSON.stringify(this.allReportThemes),
            );
          }.bind(this),
        )
        .catch(
          function (error) {
            // handle error
            console.error(error);
            this.emit.emit("systemMessage", {
              message: error.response.data.message,
              title: "Error! Failed to get all reports",
              timeout: -1,
              colour: "red",
            });
          }.bind(this),
        );
    },
    getSelectedReportTheme() {
      this.emit.emit("systemBusy", true);
      let i = 0;
      let areadCode = null;
      let url = "/reports-themes-editor/" + this.selectedReport.id;
      while (i < 3) {
        areadCode = null;
        if (typeof this.selectedComparators[i] !== "undefined") {
          areadCode = this.selectedComparators[i];
        }
        url = url + "/" + areadCode;
        i++;
      }
      this.$axios
        .get(url + "/1")
        .then(
          function (response) {
            // handle success
            this.reportThemeObject = response.data;
            this.reportThemeObjectClone = this.$cloneDeep(
              this.reportThemeObject,
            );

            // get the clients with access / hidden
            this.getClientsWithAccess();
            this.getHideThemeFromClients();

            this.emit.emit("systemBusy", false);
            this.showPanel = true;
            this.$nextTick(() => {
              this.$refs.ReportThemeEditor.setIndicatorValues();
            });
          }.bind(this),
        )
        .catch(
          function (error) {
            this.emit.emit("systemBusy", false);
            // handle error
            console.error(error);
            this.emit.emit("systemMessage", {
              message: error.response.data.message,
              title: "Error! Failed to get selected report",
              timeout: -1,
              colour: "red",
            });
          }.bind(this),
        );
    },
    upsertReportTheme() {
      this.resetApiValidationErrors();
      // if this passes validation then call the api
      if (this.$refs.form.validate()) {
        // make the call!
        this.emit.emit("systemMessage", {
          title: "Updating theme...please wait",
          message: "Updating...",
          timeout: 10000,
          colour: "warning",
        });
        let i = 0;
        let areadCode = null;
        let url = "/reports-themes/" + this.selectedReport.id;
        while (i < 3) {
          areadCode = null;
          if (typeof this.selectedComparators[i] !== "undefined") {
            areadCode = this.selectedComparators[i];
          }
          url = url + "/" + areadCode;
          i++;
        }
        var countryBeforeEditing = this.reportThemeObjectClone.country;

        // add logic of opening links in a new tab
        this.reportThemeObject.description = this.addTargetToLinks(
          this.reportThemeObject.description,
        );
        this.reportThemeObject.config.forEach((item) => {
          item.text = this.addTargetToLinks(item.text);
        });

        this.$axios
          .put(url, this.reportThemeObject)
          .then((response) => {
            this.emit.emit("systemMessage", {
              title: "Update Complete",
              message: "Saved",
              timeout: 3000,
              colour: "green",
            });
            // update the selected report
            this.reportThemeObject = response.data;
            this.reportThemeObjectClone = this.$cloneDeep(
              this.reportThemeObject,
            );

            // if the country has changed then we need to update the list of comparators
            if (countryBeforeEditing !== this.reportThemeObject.country) {
              this.getReportThemes();
              this.showPanel = false;
            }

            // refresh the list of filtered by client themes
            if (this.selectedFilterClient) {
              this.getFilteredThemesByClient(this.selectedFilterClient.id);
            }

            // this.$refs.ReportThemeEditor.setIndicatorValues();
            this.emit.emit("systemBusy", false);
          })
          .catch((error) => {
            this.emit.emit("systemBusy", false);
            this.emit.emit("systemMessage", {
              title: "Please check form for errors",
              message: "Error! Report Update Failed",
              timeout: -1,
              colour: "red",
            });
            this.apiValidationErrors(error.response.data.errors);
          });
      }
    },
    apiValidationErrors(errors) {
      for (const field in errors) {
        this.fieldSchema[field].apiResponseError = errors[field];
      }
    },
    resetApiValidationErrors() {
      for (var field in this.fieldSchema) {
        this.fieldSchema[field].apiResponseError = null;
      }
    },
    fetchClients(name) {
      this.$axios
        .get("/clients/search/" + name)
        .then((response) => {
          this.clients = response.data;
        })
        .catch((error) => {
          this.emit.emit("systemBusy", false);
          this.emit.emit("systemMessage", {
            message: error.response.data.message,
            title: "Error! Failed to get client",
            timeout: -1,
            colour: "red",
          });
        })
        .finally(() => {
          this.clientSearchLoadingBespoke = false;
          this.clientsHideSearchLoading = false;
          this.clientFilterSearchLoading = false;
        });
    },
    getClientsWithAccess() {
      this.$axios
        .get("/custom-theme-membership/" + this.selectedReport.id)
        .then((response) => {
          // handle success
          this.bespokeThemeAccess = response.data;

          // make unclickable summary report checkbox
          this.fieldSchema.use_for_summary.disabled =
            this.bespokeThemeAccess.length > 0;
        })
        .catch((error) => {
          this.emit.emit("systemMessage", {
            message: error.response.data.message,
            title: "Error! Failed to get clients with access to this theme",
            timeout: -1,
            colour: "red",
          });
        });
    },
    getHideThemeFromClients() {
      this.$axios
        .get("/hide-report-theme/" + this.selectedReport.id)
        .then((response) => {
          // handle success
          this.hideThemeClientList = response.data;
        })
        .catch((error) => {
          this.emit.emit("systemMessage", {
            message: error.response.data.message,
            title: "Error! Failed to get clients hidden from this theme",
            timeout: -1,
            colour: "red",
          });
        });
    },
    clearPanel() {
      this.showPanel = false;
      this.selectedReport.id = null;
    },
    getFilteredThemesByClient(clientID) {
      this.selectedReport = { name: null, id: null };
      this.loadingFilteredThemes = true;

      this.$axios
        .get("/get-filtered-themes-by-client/" + clientID)
        .then(
          function (response) {
            this.allReportThemes = response.data;
            this.loadingFilteredThemes = false;
          }.bind(this),
        )
        .catch(
          function (error) {
            // handle error
            console.error(error);
            this.emit.emit("systemMessage", {
              message: error.response.data.message,
              title: "Error! Failed",
              timeout: -1,
              colour: "error",
            });
          }.bind(this),
        );
    },
    discoveryAPI(
      callType = "get",
      url = "get-discovery-summary-report-theme-assignment",
    ) {
      this.discoveryLoading = true;
      this.$axios[callType](url)
        .then((response) => {
          // handle success
          this.discoveryItems = response.data;
        })
        .catch((error) => {
          this.emit.emit("systemMessage", {
            message: error.response.data.message,
            title: "Error! Failure with Discovery Tool Summary Report Theme",
            timeout: -1,
            colour: "red",
          });
        })
        .finally(() => {
          this.discoveryLoading = false;
        });
    },
  },
  watch: {
    discoveryItems: {
      handler() {
        for (var i = 0; i < this.discoveryItems.length; i++) {
          if (typeof this.discoveryItems[i].update !== "undefined") {
            if (this.discoveryItems[i].theme_id) {
              // call the update API
              this.discoveryAPI(
                "put",
                "/update-discovery-summary-report-theme/" +
                  this.discoveryItems[i].theme_id +
                  "/" +
                  this.discoveryItems[i].client_id,
              );
            } else {
              // call the delete API
              this.discoveryAPI(
                "delete",
                "/unassign-discovery-summary-report-theme/" +
                  this.discoveryItems[i].id,
              );
            }
            break;
          }
        }
      },
      deep: true,
    },
    assignDiscoveryThemeItem: {
      handler(val) {
        for (var i = 0; i < this.discoveryItems.length; i++) {
          if (this.discoveryItems[i].client_id === val.client_id) {
            this.discoveryItems[i].theme_id = this.selectedReport.id;
            this.discoveryItems[i].update = true;
          }
        }
      },
    },
    selectedComparators() {
      if (Object.prototype.hasOwnProperty.call(this.selectedReport, "id")) {
        if (this.selectedReport.id) {
          this.getSelectedReportTheme();
        }
      }
    },
    selectedAreaLevel(val) {
      this.selectedAreaLevelsAreas = [];
      if (val) {
        this.getAreaLevelsAreas();
      }
    },
    area(val) {
      if (val) {
        // need to add function to check this isn't already in the list....
        this.comparatorsList.push(val);
        // same check again
        this.selectedComparators.push(val.area_code);
      }
    },
    selectedReport: {
      handler() {
        if (this.selectedReport && this.selectedReport.id) {
          this.getSelectedReportTheme();

          // remove focus form the search box
          this.$refs.selectReportTheme.isMenuActive = false;
          this.$refs.selectReportTheme.isFocused = false;
        } else {
          this.showPanel = false;
        }
      },
      deep: true,
    },
    searchClientBespoke(val) {
      // prevent the search from firing too often
      clearTimeout(this.searchIteration);

      this.searchIteration = setTimeout(
        function () {
          // check if the value is not already in the list
          if (
            val &&
            this.clients &&
            !this.clients.some((item) => item.client_name === val)
          ) {
            this.clientSearchLoadingBespoke = true;
            this.fetchClients(val);
          }
        }.bind(this),
        500,
      );
    },
    searchClientHide(val) {
      // prevent the search from firing too often
      clearTimeout(this.searchIteration);

      this.searchIteration = setTimeout(
        function () {
          // check if the value is not already in the list
          if (
            val &&
            this.clients &&
            !this.clients.some((item) => item.client_name === val)
          ) {
            this.clientsHideSearchLoading = true;
            this.fetchClients(val);
          }
        }.bind(this),
        500,
      );
    },
    searchFilterClient(val) {
      // prevent the search from firing too often
      clearTimeout(this.searchIteration);

      this.searchIteration = setTimeout(
        function () {
          // check if the value is not already in the list
          if (
            val &&
            this.clients &&
            !this.clients.some((item) => item.client_name === val)
          ) {
            this.clientFilterSearchLoading = true;
            this.fetchClients(val);
          }
        }.bind(this),
        500,
      );
    },
    selectedClientBespoke: {
      handler(val) {
        if (val) {
          this.clientSearchLoadingBespoke = true;
          this.$axios
            .post("/custom-theme-membership", {
              reportThemeID: this.selectedReport.id,
              client_id: this.selectedClientBespoke.id,
            })
            .then((response) => {
              // handle success
              this.bespokeThemeAccess = response.data;

              // if summary report is checked then uncheck it
              if (this.reportThemeObject.use_for_summary) {
                this.reportThemeObject.use_for_summary = false;
                this.upsertReportTheme();
              }

              // make unclickable summary report checkbox
              if (this.bespokeThemeAccess.length > 0) {
                this.fieldSchema.use_for_summary.disabled = true;
              }

              // refresh the list of filtered by client themes
              if (this.selectedFilterClient) {
                this.getFilteredThemesByClient(this.selectedFilterClient.id);
              }
            })
            .catch((error) => {
              this.emit.emit("systemMessage", {
                message: error.response.data.message,
                title: "Error! Failed to add client to theme",
                timeout: -1,
                colour: "red",
              });
            })
            .finally(() => {
              // reset the search
              this.clientSearchLoadingBespoke = false;
              this.selectedClientBespoke = null;
              this.searchClientBespoke = null;
            });
        }
      },
    },
    selectedClientHide: {
      handler(val) {
        if (val) {
          this.clientsHideSearchLoading = true;
          this.$axios
            .post("/hide-report-theme", {
              reportThemeID: this.selectedReport.id,
              client_id: this.selectedClientHide.id,
            })
            .then((response) => {
              // handle success
              this.hideThemeClientList = response.data;

              // refresh the list of filtered by client themes
              if (this.selectedFilterClient) {
                this.getFilteredThemesByClient(this.selectedFilterClient.id);
              }
            })
            .catch((error) => {
              this.emit.emit("systemMessage", {
                message: error.response.data.message,
                title: "Error! Failed to add client to theme",
                timeout: -1,
                colour: "red",
              });
            })
            .finally(() => {
              // reset the search
              this.clientsHideSearchLoading = false;
              this.selectedClientHide = null;
              this.searchClientHide = null;
            });
        }
      },
    },
    selectedFilterClient: {
      handler(val) {
        if (val) {
          this.selectedReport = { id: null, name: null };
          this.getFilteredThemesByClient(val.id);
        } else {
          this.allReportThemes = this.cloneAllReportThemes;

          // turn on vuetify search engine
          this.disableVuetifyFilter = false;
        }

        // focus on the search box to highlight the changes
        this.$nextTick(() => {
          this.$refs.selectReportTheme.isMenuActive = true;
          this.$refs.selectReportTheme.isFocused = true;
        });
      },
    },
    removeBespokeClient: {
      handler(val) {
        if (val) {
          this.removeClientLoading = true;
          this.$axios
            .delete("/custom-theme-membership/" + val.id)
            .then((response) => {
              // handle success
              this.bespokeThemeAccess = response.data;

              if (this.bespokeThemeAccess.length === 0) {
                // make clickable summary report checkbox
                this.fieldSchema.use_for_summary.disabled = false;
              }

              // refresh the list of filtered by client themes
              if (this.selectedFilterClient) {
                this.getFilteredThemesByClient(this.selectedFilterClient.id);
              }
            })
            .catch((error) => {
              this.emit.emit("systemMessage", {
                message: error.response.data.message,
                title: "Error! Failed to remove client from theme",
                timeout: -1,
                colour: "red",
              });
            })
            .finally(() => {
              // reset the values
              this.removeBespokeClient = null;
              this.removeClientLoading = false;
            });
        }
      },
    },
    removeHiddenFromClient: {
      handler(val) {
        if (val) {
          this.removeHiddenFromClientLoading = true;
          this.$axios
            .delete("/hide-report-theme/" + val.id + "/" + val.theme_id)
            .then((response) => {
              // handle success
              this.hideThemeClientList = response.data;

              // refresh the list of filtered by client themes
              if (this.selectedFilterClient) {
                this.getFilteredThemesByClient(this.selectedFilterClient.id);
              }
            })
            .catch((error) => {
              this.emit.emit("systemMessage", {
                message: error.response.data.message,
                title: "Error! Failed to un-hide client from theme",
                timeout: -1,
                colour: "red",
              });
            })
            .finally(() => {
              // reset the values
              this.removeHiddenFromClient = null;
              this.removeHiddenFromClientLoading = false;
            });
        }
      },
    },
    searchTheme: {
      handler(val) {
        // searching for filtered themes needs different logic due to the structure of the data
        if (!this.selectedFilterClient || this.loadingFilteredThemes) return;

        // turn off vuetify search engine, as we are using our own
        this.disableVuetifyFilter = true;

        const themes = this.allReportThemes[0];
        const searchValue = val.toLowerCase();
        // if theme is selected then show the whole list of filtered
        const showAll = this.selectedReport && this.selectedReport.name === val;

        for (let key in themes) {
          for (let theme of themes[key]) {
            theme.show =
              showAll || theme.name.toLowerCase().includes(searchValue);
          }
        }
      },
    },
  },
};
</script>

<style>
/* styling for tip tap word count */
.text-overline {
  color: #000 !important;
}
</style>
