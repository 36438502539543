<template>
  <div>
    <!-- name dialog -->
    <DynamicDialog
      :show="showNameDialog"
      maxWidth="500px"
      :okBtnDisabled="areaName.length === 0"
      @dialogOk="fetchSummaryReport()"
      @dialogCancel="errors = []"
      @update:showDynamicDialog="(state) => (showNameDialog = state)"
    >
      <template v-slot:title>Area Name</template>
      <template v-slot:content>
        <p class="pb-4">Give your area a name</p>
        <v-text-field
          v-model="areaName"
          label="Area name"
          variant="outlined"
          :error-messages="errors"
        ></v-text-field>
      </template>
      <template v-slot:okBtnTitle>SHOW SUMMARY REPORT</template>
    </DynamicDialog>

    <!-- summary report dialog -->
    <DynamicDialog
      :show="showSummaryDialog"
      minHeight="250px"
      maxWidth="80vw"
      @dialogOk="fetchReport()"
      @update:showDynamicDialog="(state) => (showNameDialog = state)"
    >
      <template v-slot:title>Summary Report</template>
      <template v-slot:content>
        <div v-if="!loading">
          <!-- summary report content (ReportViewer cust and paste)-->
          <h2 class="ml-4">{{ summaryTheme.title }}</h2>
          <v-card-text>
            <div
              v-html="summaryTheme.description"
              class="min-accessible-size text"
            />
          </v-card-text>
          <v-card-actions v-for="(item, j) in summaryTheme.config" :key="j">
            <!-- Text -->
            <v-card-text v-if="item.text">
              <div v-html="item.text" class="min-accessible-size text" />
            </v-card-text>
            <!-- Table -->
            <v-card-actions
              v-if="item.indicatorHTMLTableRef"
              :id="j.toString()"
            >
              <Tables
                :load="summaryFetched"
                :item="item"
                :indicatorValues="summaryIndicators"
                :comparators="selectedComparators"
                :comparatorsList="comparatorsList"
                :reportThemeObject="summaryTheme"
              />
            </v-card-actions>
            <!-- Chart -->
            <v-card-actions v-if="item.chartType" :id="j.toString()">
              <Charts
                :load="summaryFetched"
                :item="item"
                :indicatorValues="summaryIndicators"
                :comparators="selectedComparators"
                :comparatorsList="comparatorsList"
                :reportThemeObject="summaryTheme"
                chartWidth="73vw"
                chartHeight="400px"
              />
            </v-card-actions>
          </v-card-actions>
        </div>
        <div v-else>
          <v-layout justify-center>
            <v-progress-circular
              indeterminate
              :size="128"
              class="w-100"
              align="center"
              ><span class="text-body-2"
                >Retrieving Report</span
              ></v-progress-circular
            ></v-layout
          >
        </div>
      </template>
      <template v-slot:okBtnTitle>VIEW REPORT</template>
      <template v-slot:cancelBtnTitle>CLOSE</template>
    </DynamicDialog>
  </div>
</template>

<script>
import DynamicDialog from "@/components/DynamicDialog.vue";
import Charts from "@/components/Charts";
import Tables from "@/components/Tables";
import { systemMessages } from "@/mixins/SystemMessages";

export default {
  name: "DiscoveryToolReportDialog",
  data: () => ({
    areaName: "Selected Areas",
    summaryFetched: false,
    summaryTheme: null,
    summaryIndicators: null,
    selectedComparators: null, //?
    comparatorsList: null, //?
    errors: [],
    loading: false,
  }),
  emits: ["update:showDTReportDialog"],
  mixins: [systemMessages],
  components: { DynamicDialog, Charts, Tables },
  props: {
    /**
     * visibility of dialog passed from parent
     */
    show: {
      type: Boolean,
      default: false,
    },
    geographies: {
      type: Array,
      default: () => [],
    },
    areas: {
      type: Array,
      default: () => [],
    },
    indicators: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    async fetchSummaryReport() {
      this.errors = [];

      if (this.areaName.length === 0) {
        this.errors.push("An area name is required");
      }

      if (this.errors.length === 0) {
        try {
          this.loading = true;
          const response = await this.$axios.post(
            "/get-summary-report-discovery-tool",
            {
              name: this.areaName,
              geographies: this.geographies,
              areas: this.areas,
              indicators: this.indicators,
              geography_type_id: this.geographyTypeId,
            },
          );
          this.summaryTheme = response.data.reportTheme;
          this.summaryIndicators = response.data.indicators;
          this.comparatorsList = response.data.comparators;
          this.selectedComparators = response.data.comparators.map((c) => {
            return c.area_code;
          });
          this.loading = false;
          this.summaryFetched = true;
        } catch (error) {
          this.loading = false;
          //catch backend name validation error
          if (
            Object.prototype.hasOwnProperty.call(error.response.data, "errors")
          ) {
            if (error.response.data.errors.name !== undefined) {
              this.errors.push(error.response.data.errors.name);
            }
          } else {
            //generic error
            this.errorMsg({
              title: "Summary Report Error",
              message: error.response.data.message,
            });
          }
        }
      }
    },

    async fetchReport() {
      //go get the full report?
    },

    /** dialog has been closed and user has changed selection of area checkboxes */
    selectedAreasChanged() {
      this.summaryFetched = false;
    },

    /** user has changed the indicator selection */
    selectedIndicatorChanged() {
      this.summaryFetched = false;
    },
  },
  computed: {
    showNameDialog: {
      get: function () {
        //get parent v-model state
        return this.show && this.summaryFetched === false;
      },
      set: function (newVal) {
        //update parent v-model state
        this.$emit("update:showDTReportDialog", newVal);
      },
    },
    showSummaryDialog: {
      get: function () {
        return (
          this.show && (this.summaryFetched === true || this.loading === true)
        );
      },
      set: function (newVal) {
        //update parent v-model state
        this.$emit("update:showDTReportDialog", newVal);
      },
    },
    geographyTypeId: {
      get: function () {
        return this.$store.state.DTSelectedStdGeography;
      },
    },
  },
};
</script>

<style scoped></style>
